import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    name: 'home',
    path: '/',
    component: () => import('@/views/NotFoundView.vue'),
    meta: {
    }
  },
  {
    name: 'notfound',
    path: '/404',
    component: ()=> import('@/views/NotFoundView.vue')
  },
  {
    name: 'uid',
    path: '/uid/:unitid/:token',
    component: () => import('@/views/UnitStreamView.vue'),
    meta: {
    }
  },
  {
    name: 'unknown',
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/NotFoundView.vue'),
    meta: {
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
